import { ReactElement } from 'react'
import { Flex, HStack, Text, Tooltip } from '@chakra-ui/react'
import { useIsOnMobile } from 'hooks/useIsOnMobile'

export const TableTag = ({
  tooltip,
  icon,
  bgColor = 'brand.blue100',
  textColor = 'white',
  height = '100%',
  isAbsolutePosition = false,
}: {
  tooltip: string
  icon: ReactElement
  bgColor?: string
  textColor?: string
  height?: string
  isAbsolutePosition?: boolean
}) => {
  const isOnMobile = useIsOnMobile()

  return isOnMobile ? (
    <HStack
      w="full"
      px="8px"
      h="32px"
      align="center"
      justify="start"
      borderRadius="12px"
      bg={bgColor}
    >
      {icon}
      <Text color={textColor}>{tooltip}</Text>
    </HStack>
  ) : (
    <Tooltip label={tooltip}>
      <Flex
        bg={bgColor}
        width="20px"
        h={height}
        {...(isAbsolutePosition && { position: 'absolute', top: '-12px', left: '-24px' })}
        borderLeftRadius="8px"
        align="center"
      >
        {icon}
      </Flex>
    </Tooltip>
  )
}
