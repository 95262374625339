import React from 'react'
import {
  Avatar,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
  Flex,
  GridItem,
  HStack,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import { Demand, DemandStatus } from 'generated/generated-graphql'
import { map } from 'lodash'
import { transparentize } from 'polished'
import { AttachmentList } from 'components/AttachmentList'
import { GoogleEmbedMap } from 'components/common/GoogleEmbedMap'
import { useAuth } from 'providers/UserProvider'
import { renderAdditionalData } from 'routes/DemandDetail/DemandDetail'
import { getFormattedDateWithTime } from 'utils/date'
import { DrawerFooter } from './DrawerFooter'

interface DemandDetailDrawerProps extends Omit<DrawerProps, 'children'> {
  isOpen: boolean
  onClose: () => void
  demand: Demand
}

export const DemandDetailDrawer = ({ isOpen, onClose, demand }: DemandDetailDrawerProps) => {
  const { isValidContractor, userData } = useAuth()
  const {
    name,
    status,
    address,
    additionalData,
    category,
    files,
    listingType,
    description,
    createdAt,
    contactInfo,
    userId,
    subCategories,
    apartmentBuildingInfo,
    numberOfAttachments,
  } = demand

  const isOwnerOfDemand = userData?.id === userId
  const isPublished = status === DemandStatus.Published
  console.log('files', files)
  return (
    <Drawer isOpen={isOpen} placement="right" size="xl" onClose={onClose}>
      <DrawerOverlay bg={transparentize(0.4, '#16306B')} />
      <DrawerContent px="24px" py="32px">
        <DrawerCloseButton />
        <DrawerBody>
          <VStack w="full" align="start" spacing="24px" divider={<Divider borderColor="#CCCCCC" />}>
            <Flex w="full" justify="space-between" gap="48px">
              <VStack align="start" spacing={0}>
                <Text fontFamily="DM Sans Bold" fontSize="18px" color="brand.blue100" noOfLines={2}>
                  {name}
                </Text>
                <Text fontFamily="DM Sans Medium">{category?.name}</Text>
                <Text fontSize="12px" color="brand.darkBlue60">
                  {listingType?.name}
                </Text>
              </VStack>

              <VStack align="end" spacing="4px">
                <VStack>
                  <Text>Dátum vytvorenia</Text>
                  <Text>{getFormattedDateWithTime(createdAt)}</Text>
                </VStack>
                <HStack>
                  <Avatar
                    src=""
                    size="sm"
                    name={contactInfo?.firstName}
                    borderRadius="12px"
                    bg="brand.blue20"
                  />
                  <Text>{contactInfo?.firstName}</Text>
                </HStack>
              </VStack>
            </Flex>
            <VStack align="start" spacing="16px">
              <Text fontFamily="DM Sans Semibold" fontSize="20px">
                Popis
              </Text>
              <Text>{description || 'Bez popisu'}</Text>
            </VStack>
            {subCategories && subCategories.length !== 0 && (
              <VStack spacing="16px" align="start">
                <Text fontSize="20px" fontFamily="DM Sans SemiBold">
                  Rozsah prác
                </Text>
                <VStack align="start">
                  <UnorderedList>
                    {subCategories.map(({ name }) => (
                      <ListItem>
                        <Text>{name}</Text>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </VStack>
              </VStack>
            )}
            {(apartmentBuildingInfo || additionalData) && (
              <VStack w="full" align="start" spacing="16px">
                <Text fontFamily="DM Sans Semibold" fontSize="20px">
                  {apartmentBuildingInfo ? 'Údaje o bytovom dome' : 'Technické špecifikácie'}
                </Text>
                {apartmentBuildingInfo ? (
                  <SimpleGrid columns={2} gap="8px">
                    <Text color="brand.darkBlue80">Konštrukcia bytoveho domu</Text>
                    <Text>{apartmentBuildingInfo.constructionMaterial}</Text>
                    <Text color="brand.darkBlue80">Stav bytoveho domy</Text>
                    <Text>{apartmentBuildingInfo.condition}</Text>
                    <Text color="brand.darkBlue80">Počet bytov</Text>
                    <Text>{apartmentBuildingInfo.numberOfApartmentUnits}</Text>
                    <Text color="brand.darkBlue80">Počet poschodí</Text>
                    <Text>{apartmentBuildingInfo.numberOfFloors}</Text>
                    <Text color="brand.darkBlue80">Počet vchodov</Text>
                    <Text>{apartmentBuildingInfo.numberOfEntrances}</Text>
                    <Text color="brand.darkBlue80">Rok výstavby</Text>
                    <Text>{apartmentBuildingInfo.yearOfBuilt}</Text>
                    <Text color="brand.darkBlue80">Výťah</Text>
                    <Text>{apartmentBuildingInfo.elevator ? 'Áno' : 'Nie'}</Text>
                    <Text color="brand.darkBlue80">Balkóny / lodžie</Text>
                    <Text>{apartmentBuildingInfo.balconyType}</Text>
                    <Text color="brand.darkBlue80">Predpokladané financovanie</Text>
                    <Text>{apartmentBuildingInfo.financing?.join(', ')}</Text>
                    <GridItem colSpan={2}>
                      <VStack spacing="4px" align="start">
                        <Text color="brand.darkBlue80">Rozsah prác</Text>
                        <VStack align="start">
                          <UnorderedList>
                            {apartmentBuildingInfo.additionalInformations?.map((infoText) => (
                              <ListItem>
                                <Text>{infoText}</Text>
                              </ListItem>
                            ))}
                          </UnorderedList>
                        </VStack>
                      </VStack>
                    </GridItem>
                  </SimpleGrid>
                ) : (
                  <SimpleGrid w="full" columns={2} gap="8px">
                    {map(additionalData, (value) => renderAdditionalData(value))}
                  </SimpleGrid>
                )}
              </VStack>
            )}
            {(files || numberOfAttachments) && (
              <VStack align="start" spacing="16px">
                <Text fontFamily="DM Sans Semibold" fontSize="20px">
                  Prílohy
                </Text>
                {files && files?.length !== 0 && <AttachmentList files={files} />}
                {!files && numberOfAttachments && (
                  <Text fontFamily="Dm Sans Medium">Počet príloh {numberOfAttachments}</Text>
                )}
              </VStack>
            )}
            {address && (
              <VStack w="full" align="start" spacing="16px">
                <Text fontFamily="DM Sans Semibold" fontSize="20px">
                  Lokalita
                </Text>
                <Text fontSize="12px">
                  {address.cityRegion} | {address.postCode} {address.city}
                </Text>
                {isValidContractor && <GoogleEmbedMap googlePlacesId={address.placeId} />}
              </VStack>
            )}
          </VStack>
          {isValidContractor && isPublished && (
            <DrawerFooter demandId={demand.id} isOwnerOfDemand={isOwnerOfDemand} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
