import { Box, Button, Flex, FlexProps, HStack, Text, useBreakpointValue } from '@chakra-ui/react'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'
import { Colors } from 'theme/constants'
import paginate from 'utils/paginate'

interface PaginationProps extends FlexProps {
  totalItems: number
  pageLimit: number
  currentOffset: number
  onOffsetChange: (value: number) => void
}

const ButtonsMobileStyles = {
  height: '30px',
  minWidth: '0px',
  paddingInlineEnd: '14px',
  paddingInlineStart: '14px',
}

const ArrowButtonMobileStyles = {
  ...ButtonsMobileStyles,
  minWidth: '35px',
}

export const Pagination = ({
  totalItems,
  pageLimit,
  currentOffset,
  onOffsetChange,
  ...rest
}: PaginationProps) => {
  const isOnMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  })
  const pages = paginate({
    totalItems,
    currentOffset,
    pageLimit,
  })

  const lastVisibleOffset = currentOffset + pageLimit

  const pageCalculationText = (
    <Text textAlign={isOnMobile ? 'center' : undefined} mt="14px">
      {currentOffset} - {Math.min(lastVisibleOffset, totalItems)} z {totalItems}
    </Text>
  )

  return (
    <>
      <Flex
        w="full"
        direction={{ base: 'column', md: 'row' }}
        position="relative"
        justify="center"
        {...rest}
      >
        <Box position="absolute" left={0}>
          {!isOnMobile && pageCalculationText}
        </Box>
        <HStack w="full" spacing="8px" justify="center">
          <Button
            variant="pagination"
            color="brand.blue100"
            onClick={() => {
              onOffsetChange(currentOffset - pageLimit)
            }}
            isDisabled={currentOffset < pageLimit}
            {...(isOnMobile && ArrowButtonMobileStyles)}
          >
            <IconArrowLeft size="20px" color={Colors.brand.blue100} />
          </Button>
          {pages.map((pageNumber) => {
            if (typeof pageNumber === 'number') {
              return (
                <Button
                  key={pageNumber}
                  variant={
                    pageNumber === currentOffset / pageLimit + 1 ? 'paginationActive' : 'pagination'
                  }
                  onClick={() => {
                    onOffsetChange(pageNumber * pageLimit - pageLimit)
                  }}
                  {...(isOnMobile && ButtonsMobileStyles)}
                >
                  {pageNumber}
                </Button>
              )
            }
            return (
              <Button
                key={pageNumber}
                variant="pagination"
                isDisabled
                {...(isOnMobile && ButtonsMobileStyles)}
              >
                {pageNumber}
              </Button>
            )
          })}
          <Button
            variant="pagination"
            onClick={() => {
              onOffsetChange(currentOffset + pageLimit)
            }}
            isDisabled={currentOffset + pageLimit >= totalItems}
            {...(isOnMobile && ArrowButtonMobileStyles)}
          >
            <IconArrowRight size="20px" color={Colors.brand.blue100} />
          </Button>
        </HStack>
        {isOnMobile && (
          <Flex w="full" justify="end">
            {pageCalculationText}
          </Flex>
        )}
      </Flex>
    </>
  )
}
