import { useState } from 'react'
import {
  Box,
  Button,
  Circle,
  Flex,
  GridProps,
  HStack,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  IconFileInvoice,
  IconFireExtinguisher,
  IconPlus,
  IconSquareX,
  IconUser,
} from '@tabler/icons-react'
import {
  Demand,
  DemandListQuery,
  DemandStatus,
  DemandType,
  UserType,
} from 'generated/generated-graphql'
import Image from 'next/image'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import { UserAvatar } from 'components/common/Avatar/UserAvatar'
import { Pagination } from 'components/common/Pagination'
import { GridTable } from 'components/table/GridTable'
import { MobileTableCard } from 'components/table/MobileTableCard'
import { TableTag } from 'components/table/TableTag'
import { useAuth } from 'providers/UserProvider'
import { getFormattedDateWithTime } from 'utils/date'
import { DemandDetailDrawer } from './DemandDetailDrawer'

const PAGE_LIMIT = 12

interface DemandListTableProps {
  isLoading?: boolean
  demandListData?: DemandListQuery
  activeListingTypeId?: number
  currentOffset: number
  onOffsetChange: (value: number) => void
  rowStyles?: GridProps
  headerStyles?: GridProps
  isPublicRoute?: boolean
  hasPagination?: boolean
  pageLimit?: number
}

export const DemandListTable = ({
  isLoading = false,
  demandListData,
  activeListingTypeId,
  currentOffset,
  onOffsetChange,
  rowStyles,
  headerStyles,
  isPublicRoute = false,
  hasPagination = true,
  pageLimit = PAGE_LIMIT,
}: DemandListTableProps) => {
  const { userData } = useAuth()
  const [selectedDemand, setSelectedDemand] = useState<Demand>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isContractor = userData?.userType === UserType.Contractor

  return isLoading || demandListData?.demandList?.totalCount !== 0 ? (
    <VStack w="full" align="start" spacing="32px">
      <GridTable
        data={demandListData?.demandList?.data ?? []}
        isLoading={isLoading}
        onRowClick={(demand) => {
          setSelectedDemand(demand)
          onOpen()
        }}
        headerStyles={headerStyles}
        rowStyles={rowStyles}
        highlightedRowId={selectedDemand?.id ?? undefined}
        // highlighEmergencyDemands={!router.query.listingTypeId}
        columns={[
          ...(!isPublicRoute
            ? [
                {
                  columnStyles: { maxW: '20px', w: '20px' },
                  header: '',
                  cell: ({ status, userId, hasMyOffers }: Demand) => (
                    <Box height="200%">
                      {isContractor && status !== DemandStatus.Published && (
                        <TableTag
                          tooltip="Dopyt bol ukončený"
                          bgColor="state.error"
                          icon={<IconSquareX stroke={1.5} color="white" />}
                        />
                      )}
                      {status === DemandStatus.Published && userId === userData?.id && (
                        <TableTag
                          tooltip="Môj dopyt"
                          bgColor="brand.blue100"
                          icon={<IconUser stroke={1.5} color="white" />}
                        />
                      )}
                      {status === DemandStatus.Published && hasMyOffers && (
                        <TableTag
                          tooltip="CP vytvorená"
                          bgColor="state.success"
                          icon={<IconFileInvoice stroke={1.5} color="white" />}
                        />
                      )}
                    </Box>
                  ),
                },
              ]
            : []),
          {
            header: 'Názov / Kategória',
            cell: ({ name, category, createdAt, listingType }) => (
              <VStack align="start" spacing="8px" position="relative">
                <HStack>
                  {!activeListingTypeId && listingType?.demandType === DemandType.Emergency && (
                    <Circle size="20px" bg="state.error">
                      <IconFireExtinguisher stroke={1.5} size="16px" color="white" />
                    </Circle>
                  )}
                  <Text
                    fontFamily="DM Sans SemiBold"
                    color="brand.blue100"
                    noOfLines={1}
                    textOverflow="ellipsis"
                  >
                    {name}
                  </Text>
                </HStack>
                <Text fontFamily="DM Sans Light">
                  {getFormattedDateWithTime(createdAt)} <strong>| {category?.name}</strong>
                </Text>
              </VStack>
            ),
          },
          {
            header: 'Popis',
            cell: ({ description }) => (
              <Text fontSize="12px" noOfLines={2} textOverflow="ellipsis">
                {description}
              </Text>
            ),
          },
          {
            header: 'Lokalita',
            cell: ({ address }) => (
              <VStack align="start" spacing="8px">
                <Text fontFamily="DM Sans SemiBold" color="brand.blue100">
                  {address?.cityRegion}
                </Text>
                <Text fontFamily="DM Sans Light" noOfLines={1} textOverflow="ellipsis">
                  {address?.postCode} <strong>| {address?.city}</strong>
                </Text>
              </VStack>
            ),
          },
          {
            header: 'Dopyt vytvoril',
            cell: ({ contactInfo }) => (
              <Flex w="full" justify="end">
                <UserAvatar
                  email={contactInfo?.email}
                  isWithName
                  name={`${contactInfo?.firstName}`}
                />
              </Flex>
            ),
          },
        ]}
        mobileContent={demandListData?.demandList?.data
          // filtering is required because of interconnected results from mobile and desktop view
          .filter(Boolean)
          .map((demand) => {
            const {
              id,
              name,
              category,
              address,
              description,
              hasMyOffers,
              status,
              createdAt,
              userId,
            } = demand

            return (
              <MobileTableCard
                key={id}
                content={[
                  {
                    title: () => (
                      <VStack w="full" align="start" spacing="8px">
                        {isContractor && status !== DemandStatus.Published && (
                          <TableTag
                            tooltip="Dopyt bol ukončený"
                            bgColor="state.error"
                            icon={<IconSquareX stroke={1.5} color="white" />}
                          />
                        )}
                        {status === DemandStatus.Published && userId === userData?.id && (
                          <TableTag
                            tooltip="Môj dopyt"
                            bgColor="brand.blue100"
                            icon={<IconUser stroke={1.5} color="white" />}
                          />
                        )}
                        {status === DemandStatus.Published && hasMyOffers && (
                          <TableTag
                            tooltip="CP vytvorená"
                            bgColor="state.success"
                            icon={<IconFileInvoice stroke={1.5} color="white" />}
                          />
                        )}
                        <Text w="full" fontFamily="DM Sans Light">
                          {getFormattedDateWithTime(createdAt)} <strong>| {category?.name}</strong>
                        </Text>
                      </VStack>
                    ),
                    gridItemProps: { colSpan: { base: 2, md: 3 } },
                  },
                  {
                    title: 'Názov',
                    caption: name,
                    gridItemProps: { colSpan: { base: 2, md: 1 } },
                  },
                  {
                    title: 'Popis',
                    caption: description,
                    gridItemProps: { colSpan: { base: 2, md: 2 } },
                  },
                  {
                    gridItemProps: { colSpan: { base: 2, md: 2 } },
                    title: () => (
                      <Stack direction={{ base: 'column', md: 'row' }} align="start" spacing="6px">
                        <Text fontSize="13px" fontFamily="DM Sans Semibold" lineHeight="20px">
                          Lokalita:
                        </Text>
                        <Text fontFamily="DM Sans SemiBold" color="brand.blue100">
                          {address?.cityRegion}
                        </Text>
                        <Text fontFamily="DM Sans Light" noOfLines={1} textOverflow="ellipsis">
                          {address?.postCode} <strong>| {address?.city}</strong>
                        </Text>
                      </Stack>
                    ),
                  },
                  // {
                  //   title: '',
                  //   isFullWidth: true,
                  //   caption: (
                  //     <Flex w="full" justify={isPublicRoute ? 'start' : 'end'}>
                  //       {isPublicRoute ? (
                  //         <Text isTruncated>
                  //           <strong>Vytvoril:</strong> {contactInfo?.firstName}
                  //         </Text>
                  //       ) : (
                  //         <UserAvatar
                  //           email={contactInfo?.email}
                  //           isWithName
                  //           name={`${contactInfo?.firstName}`}
                  //         />
                  //       )}
                  //     </Flex>
                  //   ),
                  // },
                  {
                    title: () => (
                      <Flex w="full" justify="end">
                        <Button
                          variant="link"
                          onClick={() => {
                            setSelectedDemand(demand)
                            onOpen()
                          }}
                        >
                          Zobrazit detail
                        </Button>
                      </Flex>
                    ),
                    gridItemProps: { colSpan: { base: 2, md: 1 } },
                  },
                ]}
              />
            )
          })}
      />
      {hasPagination &&
        demandListData?.demandList &&
        demandListData.demandList.totalCount > pageLimit && (
          <Pagination
            totalItems={demandListData?.demandList.totalCount}
            pageLimit={pageLimit}
            currentOffset={currentOffset}
            onOffsetChange={onOffsetChange}
          />
        )}
      {selectedDemand && (
        <DemandDetailDrawer
          isOpen={isOpen}
          onClose={() => {
            onClose()
            setSelectedDemand(undefined)
          }}
          demand={selectedDemand}
        />
      )}
    </VStack>
  ) : (
    <VStack w="full" align="center" spacing="32px">
      <Box display="block">
        <Image
          src="/icons/no-data.svg"
          width={398}
          height={200}
          layout="intrinsic"
          alt="prazdna-tabulka"
        />
      </Box>
      <Text fontFamily="DM Sans Medium" fontSize="24px">
        Žiadne dopyty v tejto kategórií nie sú k dispozícii
      </Text>
      <Link href={Route.App.Demand.CreateDemand()}>
        <Button leftIcon={<IconPlus />}>Vytvoriť dopyt</Button>
      </Link>
    </VStack>
  )
}
