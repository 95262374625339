import { range } from 'lodash'

interface PaginateProps {
  totalItems: number
  currentOffset: number
  pageLimit: number
  maxVisiblePages?: number
}

// Simple stupid solution
// If somebody wants to be inspired https://github.com/mui-org/material-ui/blob/1490632fc1d91a7d417e33f333db080293590301/packages/mui-material/src/usePagination/usePagination.js
const paginate = ({
  totalItems,
  currentOffset,
  pageLimit,
  maxVisiblePages = 7,
}: PaginateProps): (string | number)[] => {
  const totalPages = Math.ceil(totalItems / pageLimit)
  const currentPage = currentOffset / pageLimit + 1

  if (totalPages <= maxVisiblePages) {
    // 1 2 3 4 5 6 7
    return range(1, totalPages + 1)
  }

  // 2 represents 2 last elements (three dots and number)
  const lowerEndBoundary = maxVisiblePages - 2
  if (currentPage < lowerEndBoundary) {
    // 1 2 3 4 5 ... 10
    return [...range(1, maxVisiblePages - 1), '...', totalPages]
  }

  const upperStartBoundary = totalPages - 4
  if (currentPage > upperStartBoundary) {
    // 1 ... 6 7 8 9 10
    return [1, '...', ...range(upperStartBoundary, totalPages + 1)]
  }

  // 1 ... 5 6 7 ... 10
  return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages]
}

export default paginate
