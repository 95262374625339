import { Button, Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import Link from 'next/link'
import { transparentize } from 'polished'
import { Colors } from 'theme/constants'
import { Route } from 'constants/common/routes'
import { useAuth } from 'providers/UserProvider'

export const DrawerFooter = ({
  demandId,
  isOwnerOfDemand,
}: {
  demandId: number
  isOwnerOfDemand?: boolean
}) => {
  const { userData } = useAuth()

  return (
    <>
      <Spacer w="full" pb={userData ? '120px' : '320px'} />
      <Flex
        w="full"
        position="absolute"
        bottom={0}
        left={0}
        borderTopRadius="12px"
        py="24px"
        px="36px"
        bg={transparentize(0.2, Colors.brand.blue100)}
      >
        {userData ? (
          <HStack w="full">
            <Link href={Route.App.Demand.DemandDetail({ id: demandId })} style={{ width: '100%' }}>
              <Button w="full" variant="secondary">
                Detail dopytu
              </Button>
            </Link>
            {!isOwnerOfDemand && (
              <Link href={Route.App.Demand.CreateOffer({ demandId })} style={{ width: '100%' }}>
                <Button w="full">Vytvoriť ponuku</Button>
              </Link>
            )}
          </HStack>
        ) : (
          <VStack w="full" spacing="24px">
            <VStack w="full" spacing="8px">
              <Text fontFamily="DM Sans Bold" fontSize="16px" color="white">
                Ziskajte kontakt alebo vytvore ponuku cez nas portal
              </Text>
              <Text fontFamily="DM Sans Bold" fontSize="16px" color="white">
                Pre ziskanie dopytu je potrebne sa registovat ako dodavatel
              </Text>
            </VStack>
            <VStack w="full" align="start" spacing="8px">
              <Text fontFamily="DM Sans Bold" color="white">
                Vyhody registraie
              </Text>
              <VStack align="start" spacing="4px">
                <Text color="white">
                  Pre ziskanie dopytu je potrebne sa registovat ako dodavatel
                </Text>
                <Text color="white">
                  Pre ziskanie dopytu je potrebne sa registovat ako dodavatel
                </Text>
                <Text color="white">
                  Pre ziskanie dopytu je potrebne sa registovat ako dodavatel
                </Text>
              </VStack>
            </VStack>
            <Flex w="full" gap="24px">
              <Link href={Route.FrontPage()} style={{ width: '100%' }}>
                <Button w="full" variant="secondary">
                  Viac informacii
                </Button>
              </Link>
              <Link href={Route.SignUp()} style={{ width: '100%' }}>
                <Button w="full" variant="outline">
                  Registrovat sa
                </Button>
              </Link>
            </Flex>
          </VStack>
        )}
      </Flex>
    </>
  )
}
